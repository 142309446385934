@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.tag {
  @include tyLabelsMed;
  @include flexCenter;
  height: 20px;
  border-radius: 43px;
  padding: 9.5px 10px 10px 10px;
  display: inline-flex;
  white-space: nowrap;
  text-transform: uppercase;

  &.dark {
    background-color: $color-black;
    color: $color-yellow;
  }

  &.light {
    background-color: transparent;
    color: $color-black;
    border: 1px solid $color-black;
  }

  &.outlined {
    border: 1px solid $color-yellow;
  }
}
