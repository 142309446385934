@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.screen {
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  display: block;
  width: 240px;
  height: auto;

  @include respond(mobile) {
    width: 136px;
  }
}
.shape {
  position: absolute;
  bottom: 0;
  right: 0;
}
