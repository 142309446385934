@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.section {
  composes: landingSection from global;
  padding: 0;
  position: relative;
  @include flexCenter;
  flex-direction: column;
  padding-top: 15vh;
  overflow: visible;
  min-height: 100vh;
  height: fit-content !important;

  @include respond(xl-desktop, xxl-desktop) {
    padding-top: 22vh;
  }

  @include respond(l-desktop) {
    padding-top: 25vh;
    height: fit-content !important;
  }
}

.tagWrapper {
  position: absolute;
  left: 15%;
  top: -50%;

  @include respond(l-desktop) {
    @include absoluteCenter;
    top: -50%;
  }
}

.greyShapeWrapper {
  position: absolute;
  right: -10px;
  z-index: 2;
  scale: 0.7;
  top: 260px;

  @include respond(xxl-desktop) {
    scale: 1;
    top: 280px;
    right: 30px;
  }
  @include respond(tablet) {
    scale: 0.55;
    top: 110px;
    right: -25px;
  }
  @include respond(mobile) {
    scale: 0.5;
    top: 60px;
    right: -30px;
  }
}

.topBigShapeWrapper {
  position: absolute;
  top: -1350px;
  right: -1250px;
  visibility: hidden;
  scale: 0.7;

  @include respond(xxl-desktop) {
    scale: 1;
    top: -1615px;
  }
  @include respond(tablet) {
    scale: 0.55;
  }
  @include respond(mobile) {
    scale: 0.5;
  }
}

.title {
  @include tyH1;
  position: relative;
  overflow: visible !important;
  width: 100%;
  text-align: center;
}

.subtitle {
  @include tyBigParagraph;
  width: 50%;
  text-align: center;
  margin-bottom: 4%;

  @include respond(tablet) {
    @include tySmallParagraph;
    width: 80%;
  }
}

.milestonesWrapper {
  position: relative;
  width: 70%;
  overflow-x: hidden;
  overflow-y: visible;

  &::-webkit-scrollbar {
    display: none;
  }

  @include respond(l-desktop) {
    width: 75%;
  }
  @include respond(tablet) {
    width: 90%;
  }
}

.milestone {
  @include tyBigParagraph;
  display: flex;
  align-items: flex-end;
  gap: 35px;
  height: 50px;
  margin-top: 0.5%;

  @include respond(xxl-desktop) {
    gap: 50px;
  }
  @include respond(desktop) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    height: auto;
    margin-top: 20px;
  }
}

.detailedMilestone {
  margin-top: 25px;
  height: fit-content;

  @include respond(xxl-desktop) {
    margin-top: 0.5%;
    height: 50px;
  }
  @include respond(xl-desktop) {
    margin-top: 20px;
    height: fit-content;
  }
  @include respond(l-desktop) {
    align-items: center;
    // align-items: flex-start;
  }
  @include respond(desktop) {
    align-items: flex-start;
  }
}

.milestoneDate {
  @include tyLabelsMed;
  flex: 0 0 100px;
  text-transform: uppercase;
  margin-bottom: 0.35em;
  width: 8rem;

  span {
    margin-right: 4px;
  }

  @include respond(l-desktop) {
    margin-bottom: 0;
  }
}

.milestoneTitle {
  font-size: 2.8rem;
  line-height: 3.4rem;
  font-style: normal;
  font-weight: 450;
  text-transform: uppercase;

  @include respond(l-desktop) {
    @include tySmallParagraph;
  }
}

.milestoneDetailsWrapper {
  @include tyLabelsMed;
  display: flex;
  gap: 20px;
  margin-bottom: 0.1em;

  span {
    font-size: 2rem;
    margin-right: 4px;
  }

  @include respond(xxl-desktop) {
    gap: 50px;
  }
  @include respond(l-desktop) {
    flex-direction: column;
    gap: 8px;
    // margin-bottom: 0.25em;
  }
  @include respond(desktop) {
    margin-top: 0.55em;
  }
}

.milestoneDivider {
  @include respond(l-desktop) {
    margin-top: -0.55em;
  }
}

:export {
  colorGreyTransparent: $color-grey-transparent;
}
