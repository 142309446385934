@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: $navbar-height;
  z-index: $z-index-navbar;
  transition: $transition;

  @include respond(mobile) {
    height: $navbar-height-mobile;
  }
}

.navbarScrolledUp {
  transform: translateY(0px);
}

.navbarScrolledDown {
  transform: translateY(calc($navbar-height * -1));

  @include respond(mobile) {
    transform: translateY(calc($navbar-height-mobile * -1));
  }
}

.blur {
  backdrop-filter: blur(10px);
}

.contentWrapper {
  height: 100%;
  @include flexBetween;
  padding: 0px 80px;

  @include respond(desktop) {
    padding: 0px 40px;
  }

  @include respond(tablet) {
    padding: 0px 20px;
  }

  @include respond(mobile) {
    padding: 0px 20px;
  }
}

.logo120Container {
  width: 200px;

  @include respond(tablet) {
    width: 80px;
  }

  @include respond(mobile) {
    width: 54px;
  }
}

.logoMainContainer {
  cursor: pointer;

  @include respond(desktop) {
    width: 200px;
  }
  @include respond(tablet) {
    width: 180px;
  }

  @include respond(mobile) {
    width: 126px;
  }
}

.languageSelector {
  margin-right: 30px;
  margin-left: auto;
}

.anchorsMenu {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex: 1;
  max-width: 600px;
  text-transform: uppercase;
}

.anchorsMenuItem {
  @include flexCenter;
  height: 35px;
  cursor: pointer;
  opacity: 0.4;
  &:hover {
    text-decoration: underline;
  }

  &.selected {
    opacity: 1;
  }
}
