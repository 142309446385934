@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.modalWrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: $color-yellow;
  height: 85%;
  width: 505px;
  z-index: $z-index-modal;
  visibility: hidden;

  @include respond(mobile) {
    width: 95vw;
  }
}

.innerWrapper {
  position: 'relative';
  width: 100%;
  height: 100%;
  padding: 80px;
  display: flex;
  flex-direction: column;

  @include respond(mobile) {
    padding: 40px;
  }
}

.crossIcon {
  position: absolute;
  border: none;
  background: none;
  top: 0;
  right: 0;
  height: 42px;
  width: 42px;
  margin: 10px;
  @include flexCenter;
  cursor: pointer;
  visibility: hidden;
  z-index: 99;
}

.titleWrapper {
  border-bottom: 1px solid $color-black;
  align-self: flex-start;
  visibility: hidden;
}

.titleFirstWord {
  @include tyH3;
  margin-bottom: -10%;
  text-transform: uppercase;
}

.titleSecondWord {
  @include tyH3;
  margin-bottom: -3%;
  text-transform: uppercase;
}

.listWrapper {
  margin-top: auto;
  margin-bottom: 0;
  @include tyLabelsBold;
  font-size: 1.4rem;
  width: 300px;
  visibility: hidden;
  // text-align: justify;

  ul {
    margin: 0;
    list-style: url('/svgs/ellipse.svg');
  }

  li:not(:last-child) {
    margin-bottom: 20px;
  }
}
