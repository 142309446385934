@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.container {
  margin-top: 6rem;

  .step {
    display: flex;
    margin: 4rem 0 2rem;

    .info {
      width: 100%;

      .title {
        display: flex;

        .tag {
          margin-right: 1.5rem;
          div {
            width: 3rem;
          }
        }

        .text {
          @include tySmallParagraph;
          margin-bottom: 0.5rem;

          &.--0 {
            cursor: pointer;
            display: flex;
            align-items: center;

            div {
              margin-right: 1rem;
            }
          }
        }
      }

      // .imageContainer {
      //   padding: 3rem;
      //   background: #c9d1db;
      //   margin-top: 2rem;
      //   border-radius: 2rem;

      //   .image {
      //     width: 100%;
      //     position: relative;

      //     &.--1 {
      //       height: 300px;
      //     }
      //     &.--2 {
      //       height: 50px;
      //     }
      //     &.--3 {
      //       height: 270px;
      //     }
      //     &.--4 {
      //       height: 230px;
      //     }
      //   }
      // }

      .link {
        @include tySmallParagraph;
        color: $color-orange-dark;
        display: flex;
        align-items: center;
        margin-left: 4.5rem;

        div {
          margin-right: 1rem;
        }
      }
    }
  }
}
