@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.background {
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  mix-blend-mode: color;
  z-index: 0;
  // background-image: url('/images/private-bg.png');
  // object-fit: fill;
  // background-size: cover;
}
