@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.container {
  height: 50%;
  padding: 0 10%;
  position: relative;
  display: flex;
  // margin-bottom: 20%;

  @include respond(desktop) {
    flex-direction: column;
    padding: 0 14%;
  }
  @include respond(mobile) {
    padding: 0 5%;
    height: fit-content;
  }
}

.leftContainer {
  flex-basis: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include respond(xxl-desktop) {
    flex-basis: 40%;
  }

  @include respond(desktop) {
    flex: 1;
  }
}

.text {
  @include tySmallParagraph;
  position: relative;
  padding-top: 15%;
  margin-bottom: 15%;
  width: 85%;
  width: 90%;
  // font-size: 3.5rem;

  p {
    margin-bottom: 2%;
  }

  @include respond(xxl-desktop) {
    font-size: 2rem;
  }
  @include respond(xl-desktop) {
    margin-bottom: 0;
    // font-size: 3.5rem;
  }
  @include respond(desktop) {
    padding-top: 10%;
    width: 100%;
  }
  @include respond(large-mobile) {
    padding-top: 15%;
  }
}

.tag {
  position: absolute;
  top: 0;
}

.rightContainer {
  flex-basis: 60%;
  position: relative;

  @include respond(xxl-desktop) {
    flex-basis: 60%;
  }

  @include respond(tablet) {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 100px;
  }

  @include respond(large-mobile) {
    margin-top: 25px;
  }
}

.mainShape {
  @include absoluteCenter;
  scale: 0.8;
  margin-left: 0;
  transform-origin: top left;

  @include respond(xxl-desktop) {
    scale: 1.1;
  }
  @include respond(xl-desktop) {
    scale: 0.7;
  }
  @include respond(tablet) {
    scale: 1.2;
  }
  @include respond(large-mobile) {
    scale: 1;
  }
}

.graphicShape {
  @include absoluteCenter;
  scale: 0.8;
  transform-origin: top left;
  margin-left: 7%;

  @include respond(xxl-desktop) {
    scale: 1.1;
  }
  @include respond(xl-desktop) {
    scale: 0.7;
  }
  @include respond(desktop) {
    position: relative;
    transform: none;
    transform-origin: center;
    left: 0;
  }
  @include respond(tablet) {
    scale: 1.2;
  }
  @include respond(large-mobile) {
    scale: 1;
  }
}
