@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.newCursor {
  position: fixed;
  top: 0;
  left: 0;
  // transform: translate(0%, -50%);
  opacity: 1;
  width: 20px;
  height: 20px;
  background-color: $color-green;
  border-radius: 50%;
  z-index: $z-index-cursor;
  background-image: url('/svgs/cursor.svg');
  background-repeat: no-repeat;
  background-size: 0;
  background-position: center;
  pointer-events: none;
}
