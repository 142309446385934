// label {
//   font-weight: 700;
// }

input {
  padding: 10px;
  background-color: transparent;
  border: 1px solid $color-black;
  @include tySmallParagraph;
  text-align: center;
  width: 100%;
  height: 50px;
}

select {
  padding: 10px;
  background-color: transparent;
  border: 1px solid $color-black;
  @include tySmallParagraph;
  text-align: center;
  width: 100%;
  height: 50px;
  border-radius: 10px;
}

// select {
//   padding: 5px;
//   margin: 5px;
//   border: 1px solid black;
//   border-radius: 10px;
//   cursor: pointer;
//   font-weight: 700;
//   background-color: #d82514;
//   color: white;
// }
