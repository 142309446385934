@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 2rem;

  @include respond(l-desktop) {
    align-items: flex-start;
    flex-direction: column;

    > div:first-child {
      margin-top: 0 !important;
    }
  }

  .label {
    @include tyLabelsRegular;
    margin-right: 4rem;
    margin-top: 2rem;
  }

  .input {
    @include tySmallParagraph;
    width: 30rem;
    margin-right: 2rem;
    margin-top: 2.5rem;

    &:last-of-type {
      margin-right: 4rem;

      @include respond(large-mobile) {
        width: 100%;
        margin-right: 0;
      }
    }

    @include respond(large-mobile) {
      width: 100%;
      margin-right: 0;
    }
  }

  button {
    background-color: $color-yellow-dark;
    margin-top: 2.5rem;
    border-radius: 11% 9% 11% 10% / 53% 62% 37% 40%;

    @include respond(large-mobile) {
      width: 100%;
      height: 60px;
    }
  }
}
