@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.shape {
  position: fixed;
  height: 100%;
  width: 100%;
  @include flexCenter;

  @include respond(tablet) {
    transform: translate(245px, 10px) scale(1.9);
  }

  @include respond(mobile) {
    transform: translate(125px, 10px) scale(2.5);
  }
}
