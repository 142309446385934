@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.container {
  // border: 1px solid black;
}

.image {
  position: relative;
  display: block;
  width: 100%;
  height: 322px;
  margin-bottom: 2rem;

  @include respond(xl-desktop) {
    height: 250px;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 999;
  }
}

.title {
  @include tyLabelsBold;
}

.list {
  @include tySmallParagraph;
  margin: 0;
  list-style: url('/svgs/ellipse.svg');
  list-style-position: inside;
  padding: 1em;
  border-bottom: 1px solid $color-black;
}

.traitType {
  text-transform: uppercase;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4em;

  @include respond(large-mobile) {
    gap: 0;
  }
}

.arrow {
  margin-right: auto;

  @include respond(large-mobile) {
    display: none;
  }
}

.cta {
  button {
    padding: 0;
    min-width: auto;
    margin-left: auto;
  }
}
