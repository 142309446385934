@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.footer {
  position: relative;
  width: 100%;
  z-index: $z-index-navbar;
  height: $footer-height;
  background-color: $color-black;
  color: $color-yellow;
  padding: 20px 4.16%;
  margin-top: -1%;

  @include respond(large-mobile) {
    height: $footer-height-mobile;
  }
}

.innerWrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.contentWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    height: 80px;
  }

  @include respond(desktop) {
    flex-direction: column;
    justify-content: initial;
  }
}

.poweredBy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $color-yellow;
  flex: 0 0 25%;

  @include respond(desktop) {
    width: 100%;
    flex: 0 0 35%;
    margin-bottom: 3em;
  }

  @include respond(large-mobile) {
    flex: 0 0 15%;
    margin-top: 3em;
    margin-bottom: 6em;
    padding-bottom: 1em;
  }
}

.vary {
  @include flexCenter;
  @include tyBigParagraph;
  font-size: 5rem;

  @include respond(l-desktop) {
    font-size: 5rem;
  }

  @include respond(desktop) {
    font-size: 4rem;
  }
}

.shapeWrapper {
  flex: 0 0 9.6%;
  @include flexCenter;

  @include respond(desktop) {
    display: none;
  }
}

.subFooterWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $color-yellow;
  flex: 1;

  div:not(:last-child) {
    margin-right: 10px;
  }

  @include respond(desktop) {
    width: 100%;
    flex-wrap: wrap;
    flex: 0 0 35%;
    align-self: flex-start;
    div:not(:last-child) {
      margin-right: 0px;
    }
  }

  @include respond(large-mobile) {
    flex: 0 0 50%;
  }
}

.moreAboutManor {
  @include respond(large-mobile) {
    align-self: flex-start;
  }
}

.buttonsWrapper {
  @include flexCenter;
  gap: 1vw;

  button {
    color: $color-yellow;
    min-width: auto;
    padding: 0;
  }

  @include respond(large-mobile) {
    // flex: 0 0 50%;
    flex-direction: column;
    justify-content: flex-end;
    height: 60%;
    margin-left: auto;
    align-self: flex-start;

    button {
      padding: 0;
      min-width: auto;
      margin-left: auto;
      // justify-content: flex-end;
    }
  }
}

.followUsWrapper {
  @include flexCenter;
  @include tyLabelsMed;
  gap: 1vw;
  @include respond(large-mobile) {
    height: 40%;
    flex-basis: 100%;
    justify-content: space-between;
  }
}

.followUsText {
  text-transform: uppercase;
}

.iconsWrapper {
  display: flex;
  align-items: flex-start;
  margin-left: 0.5em;
  gap: 10px;

  svg {
    path {
      fill: $color-yellow;
    }
  }
  a {
    button {
      min-width: 0;
      width: 150px;
      height: 30px;
    }
  }
}

.icon {
  @include flexCenter;
  cursor: pointer;
}

.copyRight {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: end;
  @include tySmallParagraph;

  @include respond(large-mobile) {
    width: 100%;
    justify-content: space-between;
  }
}

.allRightsReserved {
  text-transform: capitalize;
}
