@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.spinner {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 77%;
    height: 77%;
    margin: 8px;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.primary {
  div {
    border: 8px solid $color-yellow;
    border-color: $color-yellow transparent transparent transparent;
  }
}

.secondary {
  div {
    border: 8px solid $color-black;
    border-color: $color-black transparent transparent transparent;
  }
}
