@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.modal {
  width: 45vw;
  height: fit-content;
  background: rgba(255, 255, 255, 0.14);
  backdrop-filter: blur(187px);
  border-radius: 50px;
  @include flexCenter;
  flex-direction: column;
  @include tySmallParagraph;
  padding: 5%;
  max-width: 870px;
  display: none;

  @include respond(desktop) {
    width: 80%;
  }

  @include respond(large-mobile) {
    background: none;
    width: 100%;
    border-radius: 0px;
  }
}

.title {
  @include tyBigParagraph;
  text-transform: uppercase;
  margin: 0.75em 0 1em 0;

  @include respond(large-mobile) {
    margin: 1em 0 2em 0;
  }
}

.subtitle {
  text-align: center;
  margin-bottom: 1em;
}

.subtitle1 {
  margin: 1em 0 1.5em 0;
  text-align: center;
}

.form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
}

.submitButton {
  margin-top: 0.5em;
  // width: 100%;
  @include flexCenter;

  @include respond(large-mobile) {
    margin-top: 3em;
    width: 100%;
  }
}

.inputWithError {
  border: 1px solid $color-red;
}

.errorMessage {
  color: $color-red;
  margin: 2% 0;
  height: 20px;
}

.successButton {
  height: 50px;
  width: 280px;
  background-color: $color-yellow;
  @include flexCenter;
  @include tySmallParagraph;
  margin-bottom: 3em;
  text-transform: uppercase;

  @include respond(large-mobile) {
    width: 80%;
  }

  @include respond(mobile) {
    width: 90%;
  }
}

.followText {
  text-align: center;
  margin-top: 0.8em;

  p {
    margin-bottom: 1em;
  }

  button {
    margin: 0 auto;
  }
}
