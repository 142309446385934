@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.progressBar {
  height: 3px;
  background-color: #BAB8D3;
  width: 200px;
  overflow: hidden;
  border-radius: 14px;
}
.progressBarValue {
  width: 100%;
  height: 100%;
  background-color: #27272A;
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
  border-radius: 14px;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}