@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.section {
  composes: landingSection from global;
  min-height: 100vh;
  height: fit-content !important;
  padding: 0;
  overflow-x: hidden;
  position: relative;
  padding-top: 20em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.topShapeBig {
  position: absolute;
  right: 22%;
  top: 7%;

  @media only screen and (max-width: 106.25em) {
    right: 21%;
  }
  @media only screen and (max-width: 93.75em) {
    right: 17%;
  }
  @include respond(xl-desktop) {
    transform: scale(0.7) !important;
    right: 17%;
  }
  @include respond(l-desktop) {
    transform: scale(0.7) !important;
    right: 15%;
  }
  @include respond(desktop) {
    display: none;
  }
}
.topShapeMedium {
  z-index: 2;
  position: absolute;
  right: calc(22% + 45px); // Big shape width / 2 aprox
  top: calc(7% + 83px); // Big shape height / 2
  @media only screen and (max-width: 106.25em) {
    right: calc(21% + 45px);
  }
  @media only screen and (max-width: 93.75em) {
    right: calc(17% + 45px);
  }
  @include respond(xl-desktop) {
    transform: scale(0.7) !important;
    right: calc(17% + 45px);
  }
  @include respond(l-desktop) {
    transform: scale(0.7) !important;
    right: calc(15% + 45px);
  }
  @include respond(desktop) {
    display: none;
  }
}
.topShapeSmall {
  position: absolute;
  right: 18%;
  top: calc(7% + 150px); // 90% of Big shape height aprox
  @media only screen and (max-width: 106.25em) {
    right: 17%;
  }
  @media only screen and (max-width: 93.75em) {
    right: 15%;
  }
  @include respond(xl-desktop) {
    transform: scale(0.7) !important;
    right: 13%;
  }
  @include respond(xl-desktop) {
    transform: scale(0.7) !important;
    right: 13%;
  }
  @include respond(desktop) {
    display: none;
  }
}

.title {
  @include tyH1;
  position: relative;
  overflow: visible !important;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    @include tyH1;
  }
}

.titleDivider {
  margin: 0 20px;

  @include respond(mobile) {
    margin: 0;
  }
}

.tag {
  position: absolute;
  left: 15%;
  top: -50%;

  @include respond(xl-desktop) {
    @include absoluteCenter;
    top: -50%;
  }
}

.subtitle {
  @include tyBigParagraph;
  width: 50%;
  text-align: center;
  margin-bottom: 4%;

  @include respond(tablet) {
    @include tySmallParagraph;
    width: 80%;
  }
}

.faqsContainer {
  width: 70%;
  z-index: 2;
  margin-bottom: 170px;

  &::-webkit-scrollbar {
    display: none;
  }

  @include respond(l-desktop) {
    width: 75%;
    margin-bottom: 125px;
  }
  @include respond(desktop) {
    margin-bottom: 100px;
  }
  @include respond(tablet) {
    width: 90%;
    margin-bottom: 50px;
  }
}

.faq {
  margin-bottom: 2%;
  overflow: hidden;

  @include respond(l-desktop) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    height: auto;
    margin-top: 20px;
  }
}

.faqQuestion {
  @include tyBigParagraph;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .faqIndex {
    @include tyLabelsMed;
    margin-right: 10%;

    @include respond(mobile) {
      display: none;
    }
  }

  .faqQuestionText {
    display: flex;
    align-items: center;
    flex-basis: 90%;
    text-transform: uppercase;
  }
}

.faqAnswer {
  @include tySmallParagraph;
  height: 0;
  width: 90%;
  margin-left: calc(10% + 10px);

  @include respond(mobile) {
    margin-left: 0;
  }
}

.faqDivider {
  @include respond(l-desktop) {
    margin-top: -0.55em;
  }
}

.bottomShape {
  position: absolute;
  bottom: 0;
  left: 0;

  svg {
    z-index: 1;
  }

  @include respond(l-desktop) {
    left: -250px;
    transform-origin: bottom;
  }
  @include respond(desktop) {
    left: -350px;
  }
  @include respond(mobile) {
    scale: 0.7;
    left: -500px;
  }
}
