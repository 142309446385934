@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.container {
  padding: 5rem 0 2.5rem;
  border-top: 1px solid $color-black;
  border-bottom: 1px solid $color-black;
  width: 100%;

  .title {
    @include tyH3;
    text-transform: uppercase;
    margin-bottom: 3rem;
    text-align: left;
  }
}

.form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 110rem;

  .inputsContainer {
    display: flex;
    width: 75%;
    .input {
      width: 100%;
      max-width: 25rem;
      margin: 1rem;
    }

    @include respond(desktop) {
      width: 100%;
      margin-bottom: 1rem;
    }

    @include respond(tablet) {
      flex-direction: column;
      align-items: center;
    }
  }

  @include respond(desktop) {
    flex-direction: column;
  }
}

.submitButton {
  width: 15rem;

  button {
    min-width: 15rem;
  }

  @include flexCenter;

  @include respond(large-mobile) {
    margin-top: 3em;
    width: 100%;
  }
}

.inputWithError {
  border: 1px solid $color-red;
}

.errorMessage {
  color: $color-red;
  margin: 2% 0;
  height: 20px;
  width: 100%;
  @include flexCenter;
}
