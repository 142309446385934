@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.wrapper {
  position: absolute;
  visibility: hidden;
  width: 300px;
  height: 400px;

  @include respond(xl-desktop) {
    width: 219px;
    height: 292px;
    transform: scale(0.8);
  }

  @include respond(l-desktop) {
    width: 187.5px;
    height: 250px;
  }

  @include respond(desktop) {
    width: 160px;
    height: 212px;
  }

  @include respond(tablet) {
    width: 120px;
    height: 160px;
  }

  @include respond(mobile) {
    width: 113px;
    height: 150px;
  }
}

.video {
  width: 300px;
  height: 400px;
  object-fit: cover;

  @include respond(xl-desktop) {
    width: 219px;
    height: 292px;
  }

  @include respond(l-desktop) {
    width: 187.5px;
    height: 250px;
  }

  @include respond(desktop) {
    width: 160px;
    height: 212px;
  }

  @include respond(tablet) {
    width: 120px;
    height: 160px;
  }

  @include respond(mobile) {
    width: 113px;
    height: 150px;
  }
}
