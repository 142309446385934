@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.section {
  composes: landingSection from global;
}

.contentWrapper {
  position: absolute;
  top: 10%;
  left: 10%;
  flex-direction: column;
  width: 100%;

  @include respond(xxl-desktop) {
    top: 10%;
  }

  @media only screen and (min-width: 137em) {
    top: 15%;
  }

  @include respond(xl-desktop) {
    top: 7%;
    left: 14%;
  }
}

.topShape {
  position: absolute;
  top: -35%;
  left: 23%;

  svg {
    opacity: 0;
  }
}

.bottomShape {
  position: absolute;
  bottom: -16%;
  scale: 0.8;
  left: -235px;

  @include respond(xxl-desktop) {
    scale: 1;
    left: -150px;
  }

  @include respond(xl-desktop) {
    scale: 0.7;
    left: -230px;
  }

  @include respond(l-desktop) {
    scale: 0.7;
    left: -310px;
  }

  // @media only screen and (max-height: 49.9375em) {
  //   scale: 0.75;
  //   left: -280px;
  //   bottom: -20%;
  // }

  // @media only screen and (min-height: 75em) and (orientation: portrait) {
  //   scale: 1.15;
  //   bottom: -50px;
  // }
}

.maskedImage {
  position: absolute;
  bottom: -175px;
  left: -40px;
  scale: 0.8;

  @include respond(xxl-desktop) {
    scale: 1;
    left: 65px;
  }

  @include respond(xl-desktop) {
    left: -75px;
    scale: 0.7;
  }

  @include respond(l-desktop) {
    scale: 0.7;
    left: -130px;
  }

  // @media only screen and (max-height: 49.9375em) {
  //   left: -85px;
  //   scale: 0.7;
  // }

  // @media only screen and (min-height: 75em) and (orientation: portrait) {
  //   scale: 1.15;
  //   left: -25px;
  //   bottom: -50px;
  // }
}

.videoWrapper {
  position: absolute;
  right: -27%;
  bottom: -2%;
  height: 75%;

  @include respond(xxl-desktop) {
    height: 91%;
    max-height: 90rem;
  }

  @media only screen and (min-width: 137em) {
    max-height: 105rem;
  }

  @media only screen and (min-width: 156em) {
    max-height: 120rem;
  }

  @media only screen and (min-width: 187.4375em) {
    right: -22%;
  }

  @media only screen and (min-width: 237.5em) {
    max-height: 140rem;
    right: -20%;
  }

  // @media only screen and (min-height: 75em) and (orientation: portrait) {
  //   scale: 1.75;
  //   transform: translate(-5%, -22%);
  //   max-height: 70rem;

  //   @include respond(xl-desktop) {
  //     max-height: 65rem;

  //     @media screen and (orientation: portrait) {
  //       max-height: 55rem;
  //     }
  //   }

  //   @include respond(l-desktop) {
  //     max-height: 55rem;
  //   }
  // } // 1200px

  // aspect ratio more than 1 and less than
  // 1.53
  @media only screen and (min-aspect-ratio: 10 / 10) and (max-aspect-ratio: 150 / 98) {
    // background-color: rgba(255, 0, 0, 0.5);
    max-height: 75rem;
    right: -35%;

    @include respond(l-desktop) {
      max-height: 65rem;
      right: -35%;
    }
  }
  // 1.37
  @media only screen and (min-aspect-ratio: 10 / 10) and (max-aspect-ratio: 135 / 98) {
    // background-color: rgba(234, 255, 0, 0.5);
    max-height: 70rem;
    right: -30%;

    @media only screen and (max-width: 106.25em) {
      max-height: 75rem;
    } // 1700px
    @include respond(xl-desktop) {
      max-height: 65rem;
      right: -35%;
    }
    @include respond(l-desktop) {
      max-height: 60rem;
      right: -40%;
    }
    @media only screen and (min-height: 1500px) {
      max-height: 60vh;
    }
  }
  // 1.22
  @media only screen and (min-aspect-ratio: 10 / 10) and (max-aspect-ratio: 120 / 98) {
    // background-color: rgba(0, 255, 47, 0.5);
    max-height: 55rem;
    right: -35%;
    @media only screen and (max-width: 106.25em) {
      max-height: 70rem;

      @media only screen and (min-height: 1500px) {
        max-height: 60vh;
      }
    } // 1700px
    @include respond(l-desktop) {
      max-height: 60rem;
    }
  }

  @media only screen and (min-aspect-ratio: 5 / 10) and (max-aspect-ratio: 10 / 10) {
    // background-color: rgba(0, 51, 255, 0.5);
    max-height: 40rem;
    @media only screen and (max-width: 106.25em) {
      max-height: 70rem;
    } // 1700px
    @include respond(l-desktop) {
      max-height: 60rem;
    }
    @media only screen and (max-width: 68.75em) {
      max-height: 50rem;
    } // 1100px
    @media only screen and (min-height: 1500px) {
      max-height: 60vh;
    }
  }
}

.centerShape {
  position: absolute;
  left: 25%;
  top: 5%;
  z-index: 2;
  scale: 0.7;

  @include respond(xxl-desktop) {
    scale: 1;
  }
}

.video {
  height: 100%;
  mask: url('./svgs/video-mask.svg');
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: 50% 50%;

  &.--2 {
    scale: 1.61;
    position: absolute;
    right: 34.1%;
  }
}

.videoShape {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  transform: translatex(5px) translateY(-5px);
}

.tag {
  transform: translateY(-100%);
}

.textWrapper {
  text-align: left;
}

.titleLine {
  @include tyH1;
  position: relative;
  display: flex;
  align-items: center;
  line-height: 140px;
  transition: all 1s;
  font-size: 9rem;
  height: fit-content;
  line-height: 85%;

  @include respond(xxl-desktop) {
    font-size: 11.6rem;
  }

  @media only screen and (min-width: 137em) {
    font-size: 14rem;
  }

  @include respond(xl-desktop) {
    font-size: 8rem;
    margin-right: 1%;
  }
}

.titlePrimary {
  position: relative;
}

.titleSecondary {
  position: relative;
  font-family: $font-milo-for-manor-regular;
}

.titleAppendix {
  @include tyCtas;

  &.before {
    position: absolute;
    top: -60%;
    left: -1.5%;
  }

  &.after {
    transform: translateY(30px) translateX(5px);
  }
}

.description {
  @include tySmallParagraph;
  position: relative;
  margin-top: 2%;
  max-width: 30%;
  margin-left: 20%;

  p {
    margin-bottom: 2%;
  }

  @include respond(xxl-desktop) {
    max-width: 22%;
    margin-left: 24%;
  }

  @media only screen and (min-width: 137em) {
    font-size: 2rem;
  }

  @include respond(xl-desktop) {
    margin-left: 15%;
  }

  @media only screen and (min-height: 75em) and (orientation: portrait) {
    margin-left: 0%;
  }
}

.descriptionDivider {
  margin: 1% 0 2%;
  // margin: 0 0 2%;

  @include respond(xxl-desktop) {
    margin: 2% 0 4%;
  }
}

.descriptionTitle {
  text-transform: uppercase;
}

.descriptionPager {
  font-family: $font-milo-for-manor-bold;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.015em;
  margin-top: 5%;

  // @include respond(xxl-desktop) {
  //   margin-top: 10%;
  // }
}

.arrow {
  position: absolute;
  top: -1%;
  left: -5%;
}

.scrollingText {
  position: absolute;
  bottom: 1%;
  right: 50%;
  @include tySmallParagraph;
}

.mediaWrapper {
  position: relative;
  padding-top: 25px;
}

:export {
  colorGreen: $color-green;
}
