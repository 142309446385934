@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html,
body {
  // font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
  //   Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-family: $font-milo-for-manor-bold, Arial, sans-serif;
}

html {
  box-sizing: border-box;
  font-size: 62.5% !important; // 1.6rem = 16px 1rem =10px
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  @include respond(big-desktop) {
    // font-size: 75%; //1rem = 12, 12/16
  }

  @include respond(normal-desktop) {
    // font-size: 65% !important;
  }

  @include respond(tab-land) {
    // width < 1200?
    // font-size: 55% !important; //1 rem = 9px, 9/16 = 56.25%
  }

  @include respond(tab-port) {
    // width < 900?
    // font-size: 50% !important; //1 rem = 8px, 8/16 = 50%
  }
}

a {
  color: inherit;
  text-decoration: none;
}

@import '/styles/forms.scss';

::selection {
  background-color: $color-primary;
  color: $color-white;
}

main {
  overflow: hidden;
}

.landingSection {
  position: relative;
  height: 100vh;
  width: 100vw;
}

.charWithDiamond {
  @include flexCenter;
  font-size: 2.7rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(8.5deg);
}

strong {
  font-family: $font-milo-for-manor-bold;
}

// Toastify override variables
:root {
  --toastify-color-error: #f94949;
  --toastify-color-success: #e3fc5d;
  --toastify-color-warning: #fcec5d;
  --toastify-color-info: #0f4c81;
}
.Toastify__toast {
  @include tyLabelsBold;
}

// WEB3AUTH MODAL OVERRIDES
.w3a-modal {
  z-index: 101;
}
.w3a-header__logo {
  width: 8rem !important;
}
// .w3a-wallet-connect {
//   display: none !important;
// }
.w3a-adapter-item img {
  scale: 1.25;
}
.w3a-button {
  border: 1px solid #5958572d !important;
}
.w3a-group__title {
  color: #5c6c7fb7;
}
.w3a-modal {
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}
#w3a-modal {
  background: #00000095 !important;
}
.w3a-modal,
.w3a-modal__header,
.w3a-modal__content,
.w3a-modal__footer {
  background: transparent !important;
  div,
  p,
  a,
  input {
    background: transparent !important;
    color: white !important;
    font-family: $font-milo-for-manor-bold, Arial, sans-serif !important;
    ::placeholder {
      color: white;
    }
    &:focus {
      outline: 2px solid white !important;
    }
  }
  button {
    text-transform: uppercase;
    color: black !important;
    font-family: $font-milo-for-manor-bold, Arial, sans-serif !important;
  }
  .w3a-header__title {
    text-transform: uppercase;
  }
  .w3a-adapter-list {
    justify-content: center !important;
    .w3a-button {
      border-radius: 14px !important;
    }
  }
  .w3ajs-wallet-connect__container {
    img {
      scale: 1.5;
    }
    .w3a-wallet-connect__container-desktop {
      div {
        text-transform: uppercase;
        font-size: 12px;
      }
      .w3ajs-wallet-connect-qr {
        background-color: white !important;
        padding: 20px 0 !important;
        border-radius: 14px;
      }
    }
  }
  .w3a-modal__loader-bridge {
    img {
      scale: 1.2;
    }
  }
  .w3a-modal__loader-bridge-message {
    font-size: 18px;
  }
  .w3a-spinner-power {
    opacity: 0;
  }
}
