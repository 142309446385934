@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.section {
  composes: landingSection from global;
  opacity: 0;
}

.mainShape {
  position: absolute;
  height: 100%;
  width: 100%;
  left: -8%;

  @include respond(desktop) {
    transform: scale(1.2);
  }
  @include respond(large-mobile) {
    transform: scale(2.5);
    left: -60%;
  }
}

.smallLeftShape {
  position: absolute;
  left: 29%;
  top: 11%;
}

.smallRightShape {
  position: absolute;
  left: 70%;
  top: 48%;
}

.contentWrapper {
  @include absoluteCenter;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  top: 57%;
  flex-direction: column;
  height: 50%;
  width: 100%;

  @media only screen and (max-width: 100em) {
    top: 62%;
  } //1600px

  @include respond(l-desktop) {
    justify-content: center;
    top: 55%;
  }
  @include respond(desktop) {
    top: 54%;
  }
  @include respond(tablet) {
    top: 50%;
  }
}

.textWrapper {
  position: relative;
}

.tag {
  transform: translateY(-200%);

  @include respond(l-desktop) {
    transform: translateY(-155%);
  }
  @include respond(desktop) {
    transform: translateY(-150%);
  }
  @include respond(mobile) {
    transform: translateY(-100%);
  }
}

.titleLine {
  // height: 120px;
  height: fit-content;
  line-height: 12em;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 93.75em) {
    line-height: 11em;
  }
  @include respond(xl-desktop) {
    line-height: 9.5em;
  }
  @include respond(l-desktop) {
    line-height: 7.5em;
  }
  @include respond(desktop) {
    line-height: 6.5em;
  }
  @include respond(tablet) {
    line-height: 5em;
  }
  @include respond(large-mobile) {
    line-height: 6.5em;
  }
  @include respond(mobile) {
    line-height: 4.7em;
  }
}

.firstWord {
  margin-right: 0.2em;
}

.firstWordSecondLineMobile {
  margin-left: -1%;
}

.titlePrimary {
  @include tyH1;

  @media only screen and (max-width: 100em) {
    font-size: 14em;
  }
  @include respond(xl-desktop) {
    font-size: 12rem;
  }
  @include respond(l-desktop) {
    font-size: 9rem;
  }
  @include respond(desktop) {
    font-size: 8rem;
  }
  @include respond(tablet) {
    font-size: 6rem;
  }
  @include respond(large-mobile) {
    font-size: 8rem;
  }
  @include respond(mobile) {
    font-size: 6rem;
  }
}

.subtitle {
  @include tyBigParagraph;
  margin: 35px 0;

  @include respond(desktop) {
    margin: 20px 0px 25px 0px;
  }
}

.titleAppendix {
  @include tyLabelsMed;
  position: absolute;
  top: -18%;
  left: -3%;

  @media only screen and (max-width: 93.75em) {
    top: -16%;
    left: -3%;
  }
  @include respond(l-desktop) {
    top: -14%;
    left: -4%;
  }
}

.marginSeparator {
  margin: 0px 6px;
}
