@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.section {
  composes: landingSection from global;
  height: 150vh !important;
  margin-bottom: 10%;

  @include respond(desktop) {
    height: fit-content !important;
  }
}
