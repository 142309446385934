@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.envBanner {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  height: 2rem;
  width: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(45deg) translate(32%);
  text-transform: uppercase;
  color: $color-white;
  @include tySmallParagraph;

  &.--development {
    background-color: $color-red;
  }

  &.--preview {
    background-color: $color-orange-dark;
  }
}
