@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.container {
}

.image {
  position: relative;
  width: 100%;
  height: 155px;
}

.title {
  @include tyLabelsBold;
  margin-top: 1em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.description {
  @include tySmallParagraph;
  border-bottom: 1px solid $color-black;
  padding-bottom: 1em;
}

.cta {
  width: 80%;
  margin-left: auto;

  button {
    padding: 0;
    min-width: auto;
    margin-left: auto;
  }
}
