@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.contentWrapper {
  @include flexCenter;
  flex-direction: column;
  text-align: center;
  max-width: 95%;
}

.tag {
  margin-bottom: 2%;

  @include respond(tablet) {
    margin-bottom: 4%;
  }
}

.titleLine {
  height: fit-content;
  line-height: 9em;
  white-space: pre-wrap;

  @include respond(xl-desktop) {
    line-height: 8em;
  }
  @include respond(l-desktop) {
    line-height: 6.5em;
  }
  @include respond(desktop) {
    line-height: 5em;
  }
  @include respond(tablet) {
    line-height: 7em;
  }
  @include respond(large-mobile) {
    line-height: 5.5em;
  }
  @include respond(mobile) {
    line-height: 4em;
  }

  h1 {
    @include tyH2;
  }
}

.subtitle {
  @include tySmallParagraph;
  margin: 2.5% 0;
  max-width: 38%;

  @include respond(l-desktop) {
    max-width: 52%;
  }

  @include respond(tablet) {
    margin: 5% 0;
    max-width: 60%;
  }
  @include respond(large-mobile) {
    max-width: 65%;
  }
}
