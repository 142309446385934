@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.modalWrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: $color-orange;
  height: 100vh;
  width: 100vw;
  z-index: $z-index-modal;
  visibility: hidden;
  background-image: url('/svgs/navbar-modal-bg.svg');
  background-size: cover;
}

.innerWrapper {
  position: 'relative';
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.innerWrapperPrivate {
  composes: innerWrapper;
  align-items: center;
  justify-content: center;
}

.listWrapper {
  visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 15vh;
  margin-bottom: 10%;
}

.listItem {
  background: none;
  border: none;
  border-bottom: 1px solid $color-black;
  width: 100%;
  @include tyH1;
  font-size: 5rem !important;
  text-align: center;
  padding-top: 4%;
  color: $color-black;
  text-transform: uppercase;
}

.cta {
  width: 100%;
  @include flexCenter;
}
