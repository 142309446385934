@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.lateralText {
  position: fixed;
  top: 50%;
  @include tyLabelsMed;
  text-transform: uppercase;
  z-index: $z-index-lateral-texts;
  opacity: 0;
}

.leftText {
  composes: lateralText;
  left: 0;
  writing-mode: vertical-lr;
  margin-left: 75px;
  transform: translateY(-50%) rotate(180deg);
}

.rightText {
  composes: lateralText;
  right: 0;
  margin-right: 75px;
  writing-mode: vertical-lr;
  transform: translateY(-50%);
}
