@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.button {
  position: relative;
  background: none;
  border: none;
  padding: 17px 32px;
  margin: 0;
  @include flexCenter;
  @include tyCtas;
  cursor: pointer;
  min-width: 240px;
  height: 55px;
  border-radius: 16% 17% 14% 14% / 53% 49% 53% 40%; //https://9elements.github.io/fancy-border-radius/full-control.html#53.15.62.16-60.91.63.88-55.200
  white-space: nowrap;
  color: $color-black;
  text-transform: uppercase;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

.primary {
  background-color: $color-green;
  width: 240px;
  transition: all 0.5s ease;
  &:hover {
    filter: brightness(85%);
  }
}

.private {
  transition: all 0.5s ease;
  background-color: $color-yellow;

  &:hover {
    filter: brightness(85%);
  }
}

.privateSecondary {
  composes: private;
  color: $color-yellow;
  background-color: $color-black;
}

.contentWrapper {
  overflow: hidden;
  @include flexCenter;
  width: fit-content;
  white-space: nowrap;
}

.secondaryShape {
  margin-left: 6px;
  @include flexCenter;
}

.absoluteCenter {
  @include absoluteCenter;
}

.shapesWrapper {
  @include absoluteCenter;
  z-index: -1;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
