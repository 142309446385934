@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.button {
  position: relative;
  background: none;
  border: none;
  padding: 17px 32px;
  margin: 0;
  @include flexCenter;
  @include tyCtas;
  cursor: pointer;
  min-width: 240px;
  height: 55px;
  border-radius: 16% 17% 14% 14% / 53% 49% 53% 40%; //https://9elements.github.io/fancy-border-radius/full-control.html#53.15.62.16-60.91.63.88-55.200
  white-space: nowrap;
  color: $color-black;
  text-transform: uppercase;
  @include respond(large-mobile) {
    min-width: 0;
  }
}

.arrowShape {
  transform: rotate(180deg);
  margin-right: 6px;
  @include flexCenter;
}
