@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.section {
  composes: landingSection from global;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.shapesContainer {
  position: absolute;
  inset: 0;
  @include flexCenter;
  pointer-events: none;
}

.shapeContainerPhase1 {
  composes: shapesContainer;
  transform: scale(1.5);

  @include respond(large-mobile) {
    transform: translate(-5%, -18%) scale(2);
  }
}
.shapeContainerPhase2 {
  composes: shapesContainer;
  transform: scale(1.5);

  @include respond(large-mobile) {
    transform: translateY(-20%) scale(1.9);
  }
}
.shapeContainerPhase3 {
  composes: shapesContainer;
  transform: scale(1.1);

  @include respond(large-mobile) {
    transform: translateY(-20%) scale(1.9);
  }
}

.slidesContainer {
  position: relative;
  overflow: hidden;
  overflow: visible;
  display: flex;
  height: 65%;
  width: 100%;
  padding-top: 5%;

  @media only screen and (max-width: 100em) {
    height: 70%;
  } //1600px

  @include respond(desktop) {
    height: 70%;
  }

  @include respond(tablet) {
    height: 70%;
  }

  @include respond(large-mobile) {
    height: 85%;
    padding-top: 0%;
  }
}

.slidesInner {
  position: relative;
  height: 100%;
  width: 100%;
  transform: translateX(25%);

  @include respond(tablet) {
    transform: translateX(10%);
  }

  @include respond(large-mobile) {
    transform: translateX(0%);
  }
}

.slide {
  position: absolute;
  font-size: 90px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  width: 50%;

  @include respond(xl-desktop) {
    width: 60%;
  }

  @include respond(l-desktop) {
    width: 65%;
  }

  @include respond(desktop) {
    width: 70%;
  }

  @include respond(tablet) {
    width: 75%;
  }

  @include respond(large-mobile) {
    width: 95%;
  }

  @include respond(mobile) {
    width: 100%;
  }
}

.tagWrapper {
  width: 100%;
  @include flexCenter;
  height: 100px;
  margin-top: 5rem;
  z-index: 1;
  @include respond(large-mobile) {
    height: 40px;
  }
}

.titleWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.titleTag {
  position: absolute;
  top: 27%;
  left: -6%;
  @include tyLabelsMed;

  @include respond(tablet) {
    top: 25%;
    left: -5%;
  }
  @include respond(large-mobile) {
    top: 25%;
    left: -8%;
  }
}

.titleGraphic {
  position: absolute;
  height: 100%;
  width: 6.25vw;
  z-index: -1;

  @include respond(tablet) {
    width: 50px;
  }
}
.title1Graphic {
  composes: titleGraphic;
  transform: translate(-100%, 20%);

  @include respond(desktop) {
    transform: translate(-100%, 0%);
  }
  @include respond(tablet) {
    transform: translate(-45px, 0px);
  }
  @include respond(large-mobile) {
    transform: translate(-50px, 0px);
  }
  @include respond(mobile) {
    transform: translate(-50px, -24px);
  }
}
.title2Graphic {
  composes: titleGraphic;
  transform: scale(1.7) translate(-48%, 25%);

  @include respond(l-desktop) {
    transform: scale(1.7) translate(-48%, 20%);
  }
  @include respond(desktop) {
    transform: scale(1.7) translate(-50%, 0%);
  }
  @include respond(tablet) {
    transform: scale(1.7) translate(-25px, -3px);
  }
  @include respond(large-mobile) {
    transform: scale(1.7) translate(-50%, -7%);
  }
  @include respond(mobile) {
    transform: scale(1.7) translate(-22px, -15px);
  }
}
.title3Graphic {
  composes: titleGraphic;
  transform: scale(1.2) translate(-80%, 20%);

  @include respond(desktop) {
    transform: translate(-100%, 0%);
  }
  @include respond(tablet) {
    transform: translate(-50px, -8px);
  }
  @include respond(large-mobile) {
    transform: scale(1.2) translate(-80%, -5%);
  }
  @include respond(mobile) {
    transform: translate(-36px, -25px);
  }
}

.title {
  @include tyH1;
  font-feature-settings: 'pnum' on, 'lnum' on;
  text-transform: uppercase;
}

.titleSeparator {
  display: inline-block;
  font-size: 3rem;
  transform: translate(10vw, 10px) rotate(8.5deg);
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 106.25em) {
    transform: translate(7vw, 10px) rotate(8.5deg);
  }
  @media only screen and (max-width: 93.75em) {
    transform: translate(6vw, 10px) rotate(8.5deg);
  }
  @include respond(xl-desktop) {
    transform: translate(10vw, 10px) rotate(8.5deg);
  }
  @include respond(l-desktop) {
    transform: translate(7vw, 10px) rotate(8.5deg);
  }
  @include respond(tablet) {
    font-size: 2.5rem;
    transform: translate(12vw, 5px) rotate(8.5deg);
  }
  @include respond(large-mobile) {
    font-size: 1.5rem;
    transform: translate(8vw, 0px) rotate(8.5deg);
  }
}

.listWrapper {
  // font-family: $font-primary;
  @include tySmallParagraph;
  max-width: 90%;
  margin: 0 auto;

  ul {
    margin: 0;
    list-style-type: none;

    li {
      list-style-type: none;
      padding-left: 0.5rem;

      &::before {
        content: '.';
        font-size: 5rem;
        display: inline-block;
        transform: translate(-5px, -2px) rotate(8.5deg);
      }

      @include respond(large-mobile) {
        margin: 1rem 0;
      }
    }
  }

  @include respond(tablet) {
    max-width: 80%;
  }

  @include respond(large-mobile) {
    max-width: 85%;
  }

  @include respond(mobile) {
    max-width: 90%;
  }
}

.generalBenefitsWrapper {
  position: absolute;
  left: 0;
  top: 57%;
  margin-left: 80px;
  @include flexCenter;

  @include respond(tablet) {
    top: 75%;
    margin-left: 32px;
    padding: 1rem 0;
  }
}

.generalBenefitsText {
  text-transform: uppercase;
}

.generalButton {
  height: 20px;
  padding: 0px 30px 0px 8px;
  background-color: $color-yellow;
  display: inline-block;
  // align-items: center;
  // justify-content: flex-start;
  @include tyLabelsMed;
  line-height: 100%;
  border: 1px solid $color-yellow-dark;
  border-radius: 43px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  color: $color-black;
}

.generalButtonIcon {
  position: absolute;
  top: -2px;
  right: 0;
  left: 88%;
  height: 20px;
}
