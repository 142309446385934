@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.container {
  height: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 5%;

  @include respond(desktop) {
    padding: 10% 0;
  }
}

.mainShape {
  position: absolute;
  height: 100%;
  width: 100%;
  right: -12%;
  // top: 12%;

  @include respond(xl-desktop) {
    right: -3%;
    top: -15%;
  }

  @include respond(tablet) {
    top: 10%;
  }

  @include respond(mobile) {
    top: 0;
  }
}

.textWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media only screen and (max-width: 109.375em) {
    width: 80%;
  }
  @include respond(xl-desktop) {
    width: 90%;
  }
  @include respond(desktop) {
    width: 100%;
  }
}

.titleLine {
  height: fit-content;
  display: flex;
  align-items: center;
  padding-right: 1%;
  line-height: 85%;

  @media only screen and (max-width: 109.375em) {
    display: block;
    height: auto;
    text-align: center;
  }
}

.tag {
  z-index: 2;
}

.titleLineFirst {
  height: fit-content;
  line-height: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 35px;
  overflow: visible !important;

  @include respond(large-mobile) {
    margin-left: 0;
    display: block;
    height: auto;
    text-align: center;
  }
}

.title {
  @include tyH1;
  height: fit-content;
  line-height: 85%;
  font-size: 10rem;
  @include respond(xl-desktop) {
    font-size: 9rem;
  }
  @include respond(l-desktop) {
    font-size: 8rem;
  }
  @include respond(desktop) {
    font-size: 6rem;
  }
  @include respond(tablet) {
    font-size: 5rem;
  }
  @include respond(large-mobile) {
    font-size: 5rem;
  }
}

.titleSecondary {
  composes: title;

  &.title1 {
    position: relative;
    transform: translateX(-6%);

    @include respond(large-mobile) {
      margin-left: 10%;
    }
  }
}

.titlePrimary {
  composes: title;
  margin-right: 3rem;

  @media only screen and (max-width: 109.375em) {
    margin-right: 0;
  }
}

.subtitle {
  @include tyBigParagraph;
  margin: 35px 0;
  width: 45%;
  text-align: center;
  z-index: 2;

  @include respond(desktop) {
    margin: 0;
    width: 70%;
  }

  @include respond(mobile) {
    margin: 0;
    width: 85%;
  }
}

.titleAppendix {
  @include tyLabelsMed;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.015em;
  text-transform: uppercase;

  &.before {
    position: absolute;
    top: 9%;
    left: -9%;
  }

  &.after {
    transform: translateY(45px);
    @media only screen and (max-width: 109.375em) {
      position: absolute;
      bottom: 3%;
      right: 15%;
      transform: translateY(0);
    }
  }

  @include respond(l-desktop) {
    visibility: hidden;
  }
}
