@import "/styles/variables.scss"; @import "/styles/mixins.scss"; @import "/styles/cursor.scss"; @import "/styles/nprogress.scss";
.mainWrapper {
  @include tyLabelsMed;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100px;
  height: 50px;
}

.selectedItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  height: 100%;
  width: 100%;
}
.selectedItemText {
  margin-left: 18px;
}

.selectedItemIcon {
  width: 10px;
  margin-left: 7px;
  transform: scale(0.7);
}

.dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100%;
  background-color: $color-white;
  margin-left: 20px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgb(53 49 72 / 9%);
  padding: 25px;
  visibility: hidden;
}

.dropdownItem {
  border: none;
  background: none;
  color: $color-black;
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-align: left;
  padding: 0px 14px;
  @include tyLabelsMed;
  text-transform: uppercase;

  &:hover {
    opacity: 0.8 !important;
  }
}
